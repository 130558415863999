<template>
    <div class="templateTransition  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

        <Head></Head>
        <div class="animation" v-if="animationLoading && templateData">
            <div class="title heading_1 tc" v-html="templateData.languageConfig[$language].mainTitle"> </div>
            <div class="sub-title title_2 tc" v-html="templateData.languageConfig[$language].subTitle"> </div>
            <img v-if="templateData.mainImage" class="img" :src="require(`@img/template/${templateData.mainImage}`)"
                alt="">
        </div>
        <div class="btn-warp" v-if="templateData">
            <div @click="brandClick()" class="button active  text_1">{{
                templateData.languageConfig[$language].buttonText }}
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from '@/mixin/index';
import { Toast } from "vant"
import { setmMixpaneltSesstion } from "@/utils/mixpanel"
import {
    addFirestoreData,
    getFirestoreDataByUid,
    signOutWithEmail,
    createAnonymousAccount,
    getAnonymousAuth
} from "@/utils/firebase";
export default {
    name: "templateTransition",
    mixins: [mixinTemplate],
    components: {
        Head
    },
    methods: {
        brandClick() {
            // 检测设置setmMixpaneltSesstion缓存
            setmMixpaneltSesstion();
            //是否有匿名账号登陆
            if (getAnonymousAuth()) {
                this.contimueClick();
            } else {
                signOutWithEmail()//退出匿名账号
                this.getAnonymousAccount()//创建匿名账号
            }
        },
        async getAnonymousAccount() {
            this.toast = Toast.loading({
                duration: 0,
            });
            let uid = await createAnonymousAccount();
            //存储uid
            if (uid) {
                this.$store.commit("updateOnBoardingRecord", {
                    key: "uid",
                    value: uid,
                    noSet: true, //不需要存入数据
                });
                let res = getFirestoreDataByUid(uid);
                if (!res) {
                    addFirestoreData(uid, this.$store.state.onBoardingRecordInfo);//添加firebae数据
                }
                this.toast.clear();
                this.contimueClick();
            }
        },
    }
}
</script>
<style scoped lang="scss">
.templateTransition {

    .img {
        margin-top: 32px;
        width: 100%;
    }
}
</style>