<template>
  <div class="templateAge    template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="ipt-warp flex">
        <div class="ipt-item title_3">
          <van-field type="number" @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')"
            :style="{ width: iptWidth + 'px' }" class="ipt" :class="{ noData: ageValue == 0 }" v-model="ageValue" />
        </div>
      </div>
      <div class="ipt-tip title_3 tip-color tc">
        <span v-show="showTip"> {{ $t.EnterAValueFrom }} {{ minAge }} {{ $t.to }} {{ maxAge }}</span>
      </div>

      <div class="height-tip">
        <div class="title text_1 flex align-center">
          <img class="img" src="@img/ruler.png" width="24" alt="" />
          <div class="flex-auto">{{ $t.WeOnlyAskPlan }}</div>
        </div>
        <div class="sub-title text_3">
          {{ $t.DoNotWorry }}
        </div>
      </div>
    </div>
    <div class="btn-warp">
      <div @click="validateClick()" :class="{ active: ageValue > 0 && !showTip }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import sdkConfig from "@/config/web.config/index";
export default {
  name: "templateAge",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      iptWidth: 30,
      showTip: false,
      ageValue: 0,
      minAge: 16,
      maxAge: 99,
    };
  },
  watch: {
    ageValue(newValue, oldValue) {
      this.ageValue = this.$utils.inputValueWatch(newValue, oldValue, 99);
      this.iptEvent(this.ageValue)
      this.iptWidth = this.ageValue.toString().length * 31;
    },
  },
  mounted() {
    let key = sdkConfig.onBoardingKey[this.templateData.screenName];
    if (!key) key = this.templateData.screenName;
    if (this.$store.state.onBoardingRecordInfo[key]) {
      this.ageValue = this.$store.state.onBoardingRecordInfo[key];
    }
  },
  methods: {
    iptEvent(value) {
      this.showTip = value == 0 ? false : value < this.minAge || value > this.maxAge;
    },
    //验证
    validateClick(isNext = true) {
      if (!this.ageValue || this.ageValue < this.minAge || this.ageValue > this.maxAge) {
        return;
      }
      this.selectOption(this.ageValue * 1, isNext);
    },
  },
};
</script>
<style scoped lang="scss">
.templateAge {
  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }

  .ipt-tip {
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }

  .height-tip {
    margin: 60px auto;
    padding: 12px 16px;
    width: 100%;
    max-width: 550px;
    background: rgba(224, 224, 234, 1);
    border-radius: 8px;

    .sub-title {
      color: var(--theme_02);
    }

    .title {
      margin-top: 0;

      .img {
        margin-right: 8px;
        margin-top: -5px;
      }
    }
  }
}
</style>
