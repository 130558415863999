<template>
    <div class="templateGenerate" :style="{ 'padding-top': getTemplatePaddingTop }">

        <Head></Head>
        <div class="animation" v-if="animationLoading && templateData">
            <div class="generate-plan">
                <progressLoading @contimueClick="contimueClick()" />
            </div>
            <div class="title heading_1   tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
            <div class="sub-title title_2 tc"
                v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].subTitle)"></div>
            <div class="img-warp" ref="imgWarpRef">
                <img class="img" :style="{ left: imgLeft + 'px' }"
                    :src="require(`@img/template/${templateData.mainImage}`)" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import webConfig from '@/config/web.config/index';
import Head from "./components/head.vue"
import progressLoading from "@/components/echarts/progressLoading"
import mixinTemplate from "@/mixin";
import { getStatsigExperimentParam } from "@/utils/statsig";
export default {
    name: "templateGenerate",
    mixins: [mixinTemplate],
    components: {
        Head, progressLoading
    },
    data() {
        return {
            imgWarpWidth: 0,
            imgLeft: 0
        }
    },
    mounted() {
        this.getImgLeft();
        setTimeout(async () => {
            let paywallParam = await getStatsigExperimentParam(webConfig.statsigConfig.paywallExperimentName, webConfig.statsigConfig.paywallParamName, this.sdkConfig.statsigConfig.defaultPaywallParam);
            this.updateOnBoardingRecord({
                key: webConfig.statsigConfig.paywallParamName,
                value: paywallParam,
            });
        }, 2000);
    },
    methods: {
        // 图片移动展示
        getImgLeft() {
            if (this.$refs.imgWarpRef) {
                this.imgWarpWidth = this.$refs.imgWarpRef.offsetWidth;
                this.imgLeft = 0;
                let time = 0;
                let imgWidth = 784;
                let timer = setInterval(() => {
                    time += 10;
                    if (-(imgWidth - this.imgWarpWidth) > this.imgLeft) {
                        clearInterval(timer);
                        timer = null
                    } else {
                        this.imgLeft -= ((imgWidth - this.imgWarpWidth) / 1300);
                    }
                }, 10);
            }
        }
    }
}
</script>
<style scoped lang="scss">
.templateGenerate {
    .generate-plan {
        margin-top: 30px;
        padding: 10px 20px;
        height: 84px;
        background: var(--theme_03);
        border-radius: 8px;
        border: 1px solid #E4E4E4;
    }

    .img-warp {
        position: relative;
        margin: 20px 0;
        width: 100%;
        height: 300px;
        overflow: hidden;

        .img {
            position: absolute;
            width: 784px;
            height: 303px;
            left: 0;
            top: 0;
        }
    }


}
</style>