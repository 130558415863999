<template>
    <div class="prosess-box">
        <div class="prosess-warp ">
            <div class="flex align-center justify-between">
                <van-icon @click="$store.commit('lastTemplate')" name="arrow-left" class="back" />
                <div class="logo-warp flex align-center flex-auto justify-center">
                    <img :src="require(`@img/logo.png`)" class="logo" alt="">
                    <span class="logo-text title_2">{{ webConfig.websiteName }}</span>
                </div>

                <div class="text_2"><span class="cur-num">{{ $store.state.onBoardingRecordInfo.currentBoardingIndex
                        }}/</span><span class="total-num">{{
                            $store.getters.boardingTotal }}</span></div>
            </div>
            <van-progress class="progress" :percentage="$store.state.progress" :show-pivot="false" />
        </div>
    </div>
</template>
<script>
import logo from "@/components/onBody/components/logo";
import webConfig from '@/config/web.config/index'
export default {
    name: "prosess",
    components: {
        logo
    },
    data() {
        return {
            webConfig: null,
        }
    },
    watch: {
        '$store.state.onBoardingRecordInfo.currentBoardingIndex': {
            handler(value) {
                this.getProgress(value)
            },
            immediate: true
        }
    },
    created() {
        this.webConfig = webConfig
    },
    methods: {
        getProgress(value) {
            if (value) {
                let timmer = setInterval(() => {
                    let number = value / this.$store.getters.boardingTotal * 100;
                    if (this.$store.state.progress >= number) {
                        this.$store.commit('updateOldProgress', number);
                        this.$store.commit('updateProgress', number);
                        clearInterval(timmer)
                    } else {
                        this.$store.commit('updateProgress', this.$store.state.progress + (number - this.$store.state.oldProgress) / 10);
                    }
                }, 20);
            } else {
                return 0
            }
        }
    }
}
</script>

<style lang="scss">
.prosess-box {
    padding-top: 20px !important;
    padding-bottom: 5px !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: var(--bgColor);
}

.prosess-warp {
    margin: 0 auto;
    padding: 0px 20px;
    width: 100%;

    .back {
        color: var(--theme_01);
        font-size: 24px;
        font-weight: 700;
        cursor: pointer;
    }

    .cur-num {
        color: var(--theme_02);
    }

    .total-num {
        color: var(--theme_01);
    }

    .progress {
        margin-top: 20px;
        background: #fff;
        height: 8px;

        .van-progress__portion {
            background: var(--mainColor);
        }
    }

    .logo-warp {
        .logo {
            margin-right: var(--logoMarginRight);
            height: 24px;
        }
    }
}

@media (max-width: 576px) {
    .prosess-warp {
        padding: 0 10px;

        .logo-group {

            .logo {
                height: 24px;
            }

            .logo-text {
                font-size: 17px;
            }

        }
    }


}
</style>