<template>
  <div class="templateSingleOptionWithimg  template-btn"
    :style="{ backgroundImage: `url(${imgUrl})`, 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <img class="img" :src="require(`@img/template/${templateData.mainImage}`)" alt="" />
      <div class="option-group flex justify-between">
        <div class="option-item flex align-center tl text_1" @click="selectOption(item.originName)"
          :class="{ active: templateValue == item.originName }"
          v-for="(item, index) in templateData.languageConfig[$language].optionList">
          {{ item.option }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
  name: "templateSingleOptionWithimg",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  watch: {
    templateData() {
      if (this.templateData) {
        let imgUrl = require(`@img/template/${this.templateData.mainImage}`);
        this.imgUrl = imgUrl;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.templateSingleOptionWithimg {
  background-position: left bottom;
  background-size: 70%;
  background-repeat: no-repeat;

  .img {
    margin: 0 auto;
    width: 100%;
  }

  .option-group {
    .option-item {
      margin-bottom: 15px;
      padding: 20px 16px;
      width: 49% !important;
    }
  }
}

@media (max-width: 576px) {
  .templateSingleOptionWithimg {
    background-size: 80%;

    .option-group {
      .option-item {
        width: 100%;
      }
    }
  }
}
</style>
