
import moment from "moment";
import webConfig from "@/config/web.config";
import keywordConfig from "@/config/onboarding.config/keyword.json"
import router from "@/router";
import webLanguage from "@/config/language/webLanguage"
import mainCOnfig from "@/config/onboarding.config/main.json"
import Vue from "vue";
const utils = {
    initFt: 30.48,
    initIn: 2.54,
    initLbs: 0.45359237,
    initUnderweight: 18.5,
    initNormal: 25,
    initOverweight: 30,
    flag: true,
    // 禁用浏览器返回事件
    addpPopstateEvent() {
        // 禁用浏览器返回键
        history.pushState(null, null, window.location.href);
        // window.history.forward(0);
        window.addEventListener("popstate", utils.disableBrowserBack);
    },
    removePopstateEvent() {
        // 清除popstate事件  
        window.removeEventListener("popstate", utils.disableBrowserBack, false);
    },
    //禁用浏览器返回事件
    disableBrowserBack() {
        history.pushState(null, null, window.location.href);
    },
    // 监听输入框value变化
    // 监听输入框value变化
    inputValueWatch(newValue, oldValue, maxNumber = 9) {
        if (newValue == '') return 0
        if (oldValue == 0) newValue = newValue * 1
        let res = 0
        let str = (newValue + '').split('.');
        if ((newValue + '').includes('.')) {
            res = oldValue

        } else {
            res = Number(newValue) > maxNumber ? oldValue : newValue
        }
        return res
    },
    //数字格式化：有小数点取到小数点 ，整数不显示小数点
    formatNumber(num, fixedNumber = 1) {
        if (num) {
            num = num * 1;
            num = num.toFixed(fixedNumber);
            return num % 1 === 0 ? Math.round(num) : num;
        }
        return 0
    },

    // kg转英镑
    kgToLbs(kg, min = 0, max = 0, number = 1) {

        if (!kg) return 0;
        let res = utils.formatNumber((kg / utils.initLbs), number);
        if (min && res < min) {
            res = min
        }
        if (max && res > max) {
            res = max
        }
        return res
    },
    // 英镑转kg
    lbsToKg(lbs, min = 0, max = 0, number = 1) {

        if (!lbs) return 0;
        let res = utils.formatNumber((utils.initLbs * lbs), number);
        if (min && res < min) {
            res = min
        }
        if (max && res > max) {
            res = max
        }
        return res
    },
    // 时间格式化
    getDate(date, format = "MM/DD/YYYY") {
        if (!date) return '';
        moment.locale(Vue.prototype.$language);
        return moment(date).format(format)
    },
    // 时间戳转格式
    formattedDate(timestamp, format = 'MMM DD,YYYY') {
        if (!timestamp) return
        return moment(timestamp).format(format);
    },
    // 日期转时间戳
    formatTimestamp(date) {
        if (!date) return
        date = moment(date);
        // 转换为时间戳（毫秒）
        return date.valueOf();
    },
    // 两个时间日期的时间差（毫秒差）
    momentDifference(time1, time2, unit = '') {
        time1 = moment(time1)
        time2 = moment(time2)
        return time2.diff(time1, unit); // 默认返回毫秒
    },
    //计算num个月后的时间
    getAnyMonth(n, format = "MM/DD/YYYY") {
        const now = moment();
        return utils.getDate(now.add(n, 'months'), format)
    },
    // 获取n天后的日期
    getAnyDay(n, format = "MM/DD/YYYY") {
        const today = new Date();
        today.setDate(today.getDate() + n);
        return utils.getDate(today, format);
    },
    //获取bmi值：BMI=体重(kg)/身高(m)^2
    calculateBMI(height, weight) {
        if (height > 0 && weight > 0) {
            const heightInMeters = height / 100; // 将身高转换为米
            const bmi = weight / (heightInMeters ** 2);
            return utils.formatNumber(bmi);
        }
        return '';
    },
    // 邮箱格式验证
    emailValidate(email) {
        let reg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/
        return reg.test(email)
    },
    //密码验证
    passwordValidate(password) {
        let reg = /^(?=.*[a-zA-Z])(?=.*\d).{8,30}$/
        return reg.test(password)
    },
    //首字母转大写
    apitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    //重定向url的关键字展示
    redirectUrlKeyword(str) {
        str = utils.getAppName(str);
        let urlKeyword = router.currentRoute.query.keyword || webConfig.defaultUrlKeyword;
        let newUrlKeyword = '';
        if (webLanguage == 'en') {
            let whiteList = webConfig.urlKeywordWhitelist;
            if (!whiteList.includes(urlKeyword)) {
                urlKeyword = webConfig.defaultUrlKeyword;
            }
            newUrlKeyword = urlKeyword.replace(/([A-Z])/g, ' $1').toLowerCase();
            newUrlKeyword = newUrlKeyword.slice(1);
            str = str.replace(/\${keyword1}/g, newUrlKeyword.toUpperCase());//全大写 空格
            str = str.replace(/\${keyword2}/g, utils.apitalizeFirstLetter(newUrlKeyword));// 第一个字母大写 空格
            str = str.replace(/\${keyword3}/g, newUrlKeyword.toLowerCase()); // 全小写 空格
            str = str.replace(/\${keyword4}/g, newUrlKeyword.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())); // 全首字母大写 空格
        } else {
            newUrlKeyword = keywordConfig[webLanguage][urlKeyword];
            str = str.replace(/\${keyword1}/g, newUrlKeyword);
            str = str.replace(/\${keyword2}/g, newUrlKeyword);
            str = str.replace(/\${keyword3}/g, newUrlKeyword);
            str = str.replace(/\${keyword4}/g, newUrlKeyword);
        }

        return str
    },
    getUrlParams(url) {
        let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
        let parames = {}; // 定义参数对象
        url.replace(pattern, ($, $1, $2) => {
            parames[$1] = $2;
        });
        return parames;
    },

    // 自定义路由跳转
    routerReplace(path) {
        utils.customRouterNavigate(path, true);
    },
    routerPush(path) {
        utils.customRouterNavigate(path, false);
    },
    //路由跳转
    customRouterNavigate(path, replace = false) {
        if (path === router.currentRoute.path) return;
        const urlParams = utils.getUrlParams(window.location.href);
        console.log(urlParams);
        let query = JSON.parse(window.sessionStorage.getItem('urlQuery')) || {};
        query = Object.assign(query, urlParams);
        if (replace) {
            router.replace({
                path,
                query,
            })
        } else {
            router.push({
                path,
                query,
            })
        }
    },
    getAppName(str) {
        return str = str.replace(/\${appName}/g, mainCOnfig.appName);//全大写 空格
    },
    //文本替换变量
    replaceTextValue(str, value1, value2, value3, value4) {
        //str:文本 value1:数量 value2:周期（周/月） value3:原价 value4:支付的价格
        str = str.replace(/\${keyword1}/g, value1);//全大写 空格
        str = str.replace(/\${keyword2}/g, value2);// 第一个字母大写 空格
        str = str.replace(/\${keyword3}/g, value3);// 全小写 空格
        str = str.replace(/\${keyword4}/g, value4); // 全首字母大写 空格
        return str
    },
    replaceMetaKeyword(str, urlKeyword) {
        let whiteList = webConfig.urlKeywordWhitelist;
        if (!whiteList.includes(urlKeyword)) {
            urlKeyword = webConfig.defaultUrlKeyword;
        }
        let newUrlKeyword = urlKeyword.replace(/([A-Z])/g, ' $1').toLowerCase();
        newUrlKeyword = newUrlKeyword.slice(1);
        str = str.replace(/\${keyword1}/g, newUrlKeyword.toUpperCase());//全大写 空格
        str = str.replace(/\${keyword2}/g, utils.apitalizeFirstLetter(newUrlKeyword));// 第一个字母大写 空格
        str = str.replace(/\${keyword3}/g, newUrlKeyword.toLowerCase()); // 全小写 空格
        str = str.replace(/\${keyword4}/g, newUrlKeyword.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())); // 全首字母大写 空格
        return str
    },
    // 获取映射表别名
    getKey(key) {
        return webConfig.onBoardingKey[key] ? webConfig.onBoardingKey[key] : key
    },
    getDisplayWeight(weight, weightUnit) {
        const { weightType, weightKgUnitNumber } = webConfig;
        let convertedWeight;
        // 判断当前单位是否是 kg
        if (weightUnit === weightKgUnitNumber) {
            // 如果存储的值类型是 kg，直接使用 weight，否则将 lbs 转换为 kg
            convertedWeight = weightType === 0 ? weight : utils.lbsToKg(weight);
        } else {
            // 如果单位是 lbs，且存储的值类型是 kg，则将 kg 转换为 lbs，否则直接使用 weight
            convertedWeight = weightType === 0 ? utils.kgToLbs(weight) : weight;
        }
        // 根据传入的单位决定后缀是 'kg' 还是 'lbs'
        const unitLabel = weightUnit === weightKgUnitNumber ? 'kg' : 'lbs';
        return `${convertedWeight}${unitLabel}`;
    },
    //生成16位uuid
    generateUUID() {
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }







}
export default utils