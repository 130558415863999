<template>
  <div class="templateMultiTag  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
      <van-checkbox-group class="option-group flex" v-model="templateValue">
        <van-checkbox icon="-" class="option-item short-option-item" :name="item.originName"
          v-for="(item, index) in templateData.languageConfig[$language].optionList" :key="index">
          <div class="tc text_1">
            {{ item.option }}
          </div>
        </van-checkbox>
      </van-checkbox-group>
    </div>
    <div class="btn-warp">
      <div @click="contimueClick(true)" class="button text_1" :class="{ active: templateValue != '' }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
  name: "templateMultiTag",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
};
</script>
<style scoped lang="scss">
.templateMultiTag {
  .option-group {
    max-width: 540px;
    margin: 30px auto;
    justify-content: center;
    text-align: center;

    .option-item {
      min-height: 60px !important;
      width: auto !important;
      margin: 0 8px 16px 8px;
      padding: 16px;

      /deep/.van-checkbox__icon {
        display: none !important;
      }

      /deep/.van-checkbox__label {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
