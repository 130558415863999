<template>
  <div class="templateName    template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="ipt-warp flex">
        <div class="ipt-item title_3">
          <van-field :placeholder="$t.createAccount.Name" maxlength="30" class=" ipt" v-model="templateValue" />
        </div>
      </div>
    </div>
    <div class="btn-warp">
      <div @click="validateClick()" :class="{ active: templateValue }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import { setmMxpanelUserInfo } from "@/utils/mixpanel";
export default {
  name: "templateName",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      templateValue: "",
    };
  },
  methods: {
    //验证
    validateClick() {
      if (this.templateValue) {
        setmMxpanelUserInfo({
          $name: this.templateValue,
        })
        this.selectOption(this.templateValue, true);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.templateName {
  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 32px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }


}
</style>
