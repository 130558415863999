import { render, staticRenderFns } from "./logo.vue?vue&type=template&id=f048dda0&scoped=true"
import script from "./logo.vue?vue&type=script&lang=js"
export * from "./logo.vue?vue&type=script&lang=js"
import style0 from "./logo.vue?vue&type=style&index=0&id=f048dda0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f048dda0",
  null
  
)

export default component.exports