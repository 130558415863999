<template>
  <div class="templateTargetWeight  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="type-tabs">
        <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
          v-for="item in typeList" :key="item">
          {{ item }}
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'kg'">
        <div class="ipt-item title_3">
          <van-field type="number" @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')"
            :style="{ width: iptWidth1 + 'px' }" :class="{ noData: kgValue == 0 }" class="ipt" v-model="kgValue" />
          <div>kg</div>
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'lbs'">
        <div class="ipt-item title_3">
          <van-field type="number" @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')"
            :style="{ width: iptWidth2 + 'px' }" :class="{ noData: kgValue == 0 }" class="ipt" v-model="lbsValue" />
          <div>lbs</div>
        </div>
      </div>
      <div class="ipt-tip title_3 tip-color tc">
        <span v-show="showTip1 && kgValue && currentType == 'kg'">
          {{ $t.EnterAValueFrom }} {{ minKg }} {{ $t.to }} {{ maxKg }} KG</span>
        <span v-show="showTip2 && lbsValue">
          {{ $t.EnterAValueFrom }} {{ minLbs }} {{ $t.to }} {{ maxLbs }} LBS</span>
      </div>
      <div class="amimation-height" :class="heigthTip ? 'weight-visible' : 'hidden'">
        <div class="height-tip" v-if="tipConfig" :style="{ background: tipConfig.background }">
          <div class="flex align-center">
            <img class="img" width="24" :src="require(`@img/${tipConfig.icon}`)" alt="" />
            <div class="flex-auto">
              <strong class="title text_2" style="font-weight: 700">
                {{ tipConfig.name }}</strong>
              <span class="title text_2" style="font-weight: 400">
                {{ tipConfig.title }}
              </span>
            </div>
          </div>
          <div class="sub-title text_3">
            {{ tipConfig.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-warp">
      <div @click="validateClick()" :class="{ active: validInputValue() }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";
export default {
  name: "templateTargetWeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      heigthTip: false,
      iptWidth1: 30,
      iptWidth2: 30,
      showTip1: false,
      showTip2: false,
      typeList: ["lbs", "kg"],
      currentType: "lbs",
      kgValue: 0,
      lbsValue: 0,
      minKg: 30,
      maxKg: 250,
      minLbs: 66,
      maxLbs: 551,
      tipConfig: null,
      isActive: false,
    };
  },
  watch: {
    kgValue(newValue, oldValue) {
      this.kgValue = this.inputValueWatch(newValue, oldValue, this.minKg, this.maxKg, 999);
      this.iptWidth1 = this.kgValue.toString().length * 31;

      this.iptEvent(newValue, 2)

    },
    lbsValue(newValue, oldValue) {
      this.iptEvent(newValue, 1)
      this.lbsValue = this.inputValueWatch(newValue, oldValue, this.minLbs, this.maxLbs, 999);
      this.iptWidth2 = this.lbsValue.toString().length * 31;

    }
  },
  mounted() {
    let key = this.$utils.getKey(this.templateData.screenName);
    this.currentType = (this.$store.state.onBoardingRecordInfo[this.$utils.getKey('weightUnit')] == webConfig.weightKgUnitNumber ? 'kg' : 'lbs');
    if (this.$store.state.onBoardingRecordInfo[key]) {
      //存储为kg
      if (webConfig.weightType == 0) {
        this.kgValue = this.$store.state.onBoardingRecordInfo[key];
        this.lbsValue = this.$utils.kgToLbs(this.$store.state.onBoardingRecordInfo[key], this.minLbs, this.maxLbs);
      } else {
        this.lbsValue = this.$store.state.onBoardingRecordInfo[key];
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      }
    }
  },
  methods: {
    validInputValue() {
      let bool = false;
      if (this.currentType == 'lbs') {
        bool = (this.lbsValue && this.lbsValue >= this.minLbs && this.lbsValue <= this.maxLbs);
      } else {
        bool = (this.kgValue && this.kgValue >= this.minKg && this.kgValue <= this.maxKg);
      }
      return bool
    },
    // 监听输入框value变化
    // 监听输入框value变化
    inputValueWatch(newValue, oldValue, min, max, maxNumber = 9) {
      if (newValue == '') return 0
      if (oldValue == 0) newValue = newValue * 1
      let res = 0
      let str = (newValue + '').split('.');
      if ((newValue + '').includes('.')) {
        if (str && str[1] && str[1].length > 1) {
          res = oldValue
        }
        else {
          res = newValue
        }
      } else {
        res = Number(newValue) > maxNumber ? oldValue : newValue
      }
      return res
    },
    //输入监听
    iptEvent(value, type) {
      switch (type) {
        case 2: //kg
          this.showTip1 = !this.validInputValue()

          break;
        case 1: //lbs
          this.showTip2 = !this.validInputValue()
          break;
      }

      this.getWeightTipInfo();
    },
    // 通过bmi和身高获取标准体重
    getStandardWeight(height, type = "min") {
      if (!height) return "";
      //type:min: 健康标准值最小值  max:健康标准值最大值
      let weight = type == "min" ? this.$utils.initUnderweight : this.$utils.initNormal;
      let res = parseInt(weight * (height / 100) ** 2);
      return res;
    },
    // 获取体重变化百分比
    getWeightPercentage(weight, goalWeight) {
      let weightPercentage = "";
      if (weight == goalWeight) weightPercentage = 0;
      if (weight > goalWeight) {
        weightPercentage = 100 - Math.round((goalWeight / weight) * 100);
      } else {
        weightPercentage = Math.round((goalWeight / weight - 1) * 100);
      }
      return weightPercentage;
    },
    // 获取体重变化提示信息
    getWeightTipInfo() {
      let tipConfig = null;
      if (this.currentType == "lbs") {
        this.kgValue = this.$utils.lbsToKg(this.lbsValue);
      }
      //不在选择范围内不做计算
      if (!this.kgValue) {
        this.heigthTip = false;
        setTimeout(() => {
          this.tipConfig = tipConfig;
        }, 300);
        return
      }
      let minStandardWeight = 0,
        maxStandardWeight = 0;
      let weightPercentage = "";
      let weight = Number(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')]);
      weight = webConfig.weightType == 0 ? weight : this.$utils.lbsToKg(weight);
      let height = Number(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')]);
      let goalWeight = Number(this.kgValue);
      if (weight && height && goalWeight) {
        weightPercentage = this.getWeightPercentage(weight, goalWeight); //体重变化百分比
        minStandardWeight = this.getStandardWeight(height, "min"); //体重健康值最小值
        maxStandardWeight = this.getStandardWeight(height, "max"); //体重健康值最大值
        switch (true) {
          //目标体重相等
          case weight == goalWeight:
            tipConfig = {
              name: this.$t.KEEPFIT,
              icon: "target1.png",
              background: "#CFDFD1",
              text:
                this.$t.targetWeightDescription1,
              title: this.$t.targetWeightTitle1,
            };
            break;
          //目标体重增加
          case weight < goalWeight:
            tipConfig = {
              name: this.$t.GETSTRONG,
              icon: "target7.png",
              background: "#F3EBD4",
              text:
                this.$t.targetWeightDescription2,
              title: this.$utils.replaceTextValue(this.$t.targetWeightTitle2, weightPercentage),
            };
            break;
          case goalWeight < minStandardWeight:
            tipConfig = {
              name: this.$t.LOWWEIGHTALERT,
              icon: "target6.png",
              background: "#EED6D6",
              text: this.$utils.replaceTextValue(this.$t.targetWeightDescription3, minStandardWeight, maxStandardWeight),
              title: this.$utils.replaceTextValue(this.$t.targetWeightTitle3, weightPercentage),
            };
            break;
          // 目标体重减少
          case weight > goalWeight:
            if (weightPercentage < 5) {
              tipConfig = {
                name: this.$t.EASYWIN,
                icon: "target2.png",
                background: "#D4E4F3",
                text:
                  this.$t.targetWeightDescription4,
                title: this.$utils.replaceTextValue(this.$t.targetWeightTitle4, weightPercentage),
              };
            } else if (weightPercentage >= 5 && weightPercentage < 10) {
              tipConfig = {
                name: this.$t.REALISTICTARGET,
                icon: "target3.png",
                background: "#D4E4F3",
                text:
                  this.$t.targetWeightDescription5,
                title: this.$utils.replaceTextValue(this.$t.targetWeightTitle5, weightPercentage),
              };
            } else if (weightPercentage >= 10 && weightPercentage < 20) {
              tipConfig = {
                name: this.$t.HEALTHBENEFITS,
                icon: "target4.png",
                background: "#CFDFD1",
                text:
                  this.$t.targetWeightDescription6,
                title: this.$utils.replaceTextValue(this.$t.targetWeightTitle6, weightPercentage),
              };
            } else {
              tipConfig = {
                name: this.$t.CHALLENGINGGOAL,
                icon: "target5.png",
                background: "#CFDFD1",
                text:
                  this.$t.targetWeightDescription7,
                title: this.$utils.replaceTextValue(this.$t.targetWeightTitle7, weightPercentage),
              };
            }
            break;
        }
      }
      this.heigthTip = true;
      this.tipConfig = tipConfig;

    },
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "kg") {
        this.showTip2 = false;
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      } else {
        this.showTip1 = false;
        this.lbsValue = this.$utils.kgToLbs(this.kgValue, this.minLbs, this.maxLbs);

      }
    },

    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "lbs") {
          this.kgValue = this.$utils.lbsToKg(this.lbsValue);
        }

        this.updateOnBoardingRecord({
          key: 'weightUnit',
          value: this.currentType == 'kg' ? webConfig.weightKgUnitNumber : (webConfig.weightKgUnitNumber == 1 ? 0 : 1)
        })
        this.selectOption(webConfig.weightType == 0 ? this.kgValue * 1 : this.lbsValue * 1, isNext);
      }
    },
  },
  beforeDestroy() {
    this.validateClick(false); // 离开页面存储答案
  },
};
</script>
<style scoped lang="scss">
.templateTargetWeight {
  .type-tabs {
    margin: 50px auto;
    padding: 2px;
    display: flex;
    width: 220px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }

  .ipt-tip {
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }

  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;

    .img {
      margin-top: -5px;
      margin-right: 5px;
    }

    .title_2 {
      margin-right: 5px;
    }

    .icon {
      margin-right: 5px;
      height: 24px;
    }

    .title {
      flex: 1;
      position: relative;
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .templateTargetWeight .height-tip .title[data-v-4d1c6a21]::after {
    top: 0;
  }
}
</style>
