<template>
    <div class="templateMultiOption template  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

        <Head></Head>
        <div class="animation" v-if="animationLoading && templateData">
            <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
            <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
            <van-checkbox-group class="option-group" v-model="templateValue">
                <van-checkbox @click="checkOption(item.originName, index)" class="option-item"
                    :class="{ hiddenCheckBox: templateData.hidenCheckBox, 'margin-left': templateData.imageFull && templateData.imagePosition == 'left', 'margin-right': templateData.imageFull && templateData.imagePosition == 'right' }"
                    :name="item.originName" label-position="left"
                    v-for="(item, index) in templateData.languageConfig[$language].optionList" :key="index">
                    <div class="option  flex justify-between align-center pointer">
                        <!-- 左侧图片 -->
                        <img v-if="templateData.imageList[index] && templateData.imagePosition == 'left'"
                            class="option-img" :height="templateData.imageSize"
                            :src="require(`@img/template/${templateData.imageList[index]}`)" alt="">
                        <div class="text-warp flex justify-between align-center  flex-auto">
                            <div class="content flex-auto">
                                <div class="text text_1 flex-auto" v-html="item.option"></div>
                                <div class="sub-text text_3  flex-auto" v-html="item.subOption"></div>
                            </div>
                        </div>
                        <!-- 右侧图片 -->
                        <img v-if="templateData.imageList[index] && templateData.imagePosition == 'right'"
                            class="option-img" :height="templateData.imageSize"
                            :src="require(`@img/template/${templateData.imageList[index]}`)" alt="">
                    </div>
                </van-checkbox>

            </van-checkbox-group>

        </div>
        <div class="btn-warp">
            <div @click="contimueClick(true)" class="button  text_1"
                :class="{ active: templateValue && templateValue.length }"
                v-html="templateData.languageConfig[$language].buttonText">
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
    name: "templateMultiOption",
    mixins: [mixinTemplate],
    components: {
        Head
    },
    methods: {
        checkOption(option, index) {
            //选项选择上限
            let maxOptionNum = this.templateData.maxOptionNum;
            if (maxOptionNum && this.templateValue.length > maxOptionNum) {
                this.templateValue = this.templateValue.slice(0, maxOptionNum)
            }
            // 选项有互斥
            let mutualExclusionIndex = this.templateData.mutualExclusionIndex;
            if (mutualExclusionIndex != null || mutualExclusionIndex != undefined) {
                let mutualExclusionOption = this.templateData.languageConfig[this.$language].optionList[mutualExclusionIndex].originName;
                if (mutualExclusionIndex == index) {
                    this.templateValue = [option]
                } else {
                    if (mutualExclusionOption) {
                        this.templateValue = this.templateValue.filter(item => item != mutualExclusionOption)
                    }

                }
            }


        }
    }
}
</script>
<style lang="scss">
.on-body .template .option-group .option-item.hiddenCheckBox .van-checkbox__icon {
    width: 0 !important;
}

.on-body .template .option-group .option-item.margin-right .van-checkbox__label--left {
    margin: 0 !important;
}

.templateMultiOption {


    .option-group {
        margin-top: 30px;

        .option-item {
            padding: 0 10px;

            .text-warp {
                padding: 10px 0;
                margin-right: 5px;



                .text {
                    margin-left: 24px;
                    color: var(--theme_01);
                }

                .sub-text {
                    margin-left: 10px;
                    color: var(--theme_02);
                }
            }
        }

        .margin-left {

            padding-left: 0 !important;
        }

        .margin-right {

            padding-right: 0 !important;



        }

    }
}
</style>